/* src/app/_core/components/sidebar/components/sidebar-logo/sidebar-logo.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  flex: 0 0 140px;
  overflow: hidden;
  transition: flex 0.3s ease;
}
@media (min-width: 1200px) {
  :host.collapsed {
    flex: 0 0 40px;
  }
  :host.collapsed .logo {
    margin-left: -17px;
  }
  :host.collapsed .logo__text {
    opacity: 0;
  }
}
@media (width <= 767px) {
  :host {
    flex: 0 0 100px;
  }
}
.logo {
  display: block;
  width: 140px;
  height: auto;
  transition: margin 0.3s ease;
}
@media (width <= 767px) {
  .logo {
    width: 100px;
  }
}
.logo__text {
  transition: opacity 0.3s ease;
}
/*# sourceMappingURL=sidebar-logo.component.css.map */
