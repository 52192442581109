/* src/app/_core/components/shell/shell.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.main {
  padding: 45px 85px 35px 395px;
  transition: padding 0.3s ease;
  width: 100%;
}
@media (min-width: 1200px) {
  .main.collapsed {
    padding-left: 155px;
  }
}
@media (width <= 1199px) {
  .main {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 140px;
  }
}
@media (width <= 767px) {
  .main {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
  }
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
}
/*# sourceMappingURL=shell.component.css.map */
