/* src/app/auth/sign-in/components/sign-in-tabs/sign-in-tabs.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
:host {
  display: flex;
  justify-content: center;
}
.auth-tabs {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 60px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ced2da;
}
.auth-tab {
  display: block;
  border: none;
  padding: 0;
  background: none;
  font-size: 16px;
  text-align: left;
  color: rgba(33, 33, 33, 0.8);
  outline: none;
  cursor: pointer;
}
.auth-tab:focus-visible {
  outline: 1px solid #338054;
  outline-offset: 2px;
}
.auth-tab__active {
  height: 2px;
  background-color: #338054;
  position: absolute;
  transition:
    width 0.3s ease,
    left 0.3s ease,
    right 0.3s ease,
    transform 0.3s ease;
  left: 0;
  bottom: -1px;
}
.auth-tab__active--right {
  left: 100%;
  transform: translateX(-100%);
}
/*# sourceMappingURL=sign-in-tabs.component.css.map */
