<a routerLink="/">
  <pn-auth-header-logo class="logo"></pn-auth-header-logo>
</a>
<pn-button theme="primary" [routerLink]="signUp ? '/sign-in' : '/sign-up'">
  @if (signUp) {
    Вход
  } @else {
    Регистрация
  }
</pn-button>
