<pn-auth-head>
  <pn-title-h1 [center]="true"
    >Войти в личный <br />
    кабинет</pn-title-h1
  >
</pn-auth-head>
<pn-auth-body [formGroup]="form">
  <pn-sign-in-tabs (changeTab)="changeTab($event)"></pn-sign-in-tabs>
  @if (authType === authTypeEnum.Phone) {
    <pn-form-field pnLabelFloat>
      <pn-label [required]="true">Номер телефона</pn-label>
      <pn-input-text
        formControlName="phone"
        (keyup.enter)="submitFormPhone()"
      ></pn-input-text>
      <pn-error *ngIf="form.controls.phone.getError('required')"></pn-error>
    </pn-form-field>
  } @else {
    <pn-form-field pnLabelFloat>
      <pn-label [required]="true">Логин</pn-label>
      <pn-input-text
        formControlName="email"
        name="login"
        (keyup.enter)="submitFormLogin()"
      ></pn-input-text>
      <pn-error *ngIf="form.controls.email.getError('required')"></pn-error>
    </pn-form-field>

    <pn-form-field pnLabelFloat>
      <pn-label [required]="true">Пароль</pn-label>
      <pn-icon
        [icon]="isShowPassword ? 'eye' : 'eye-closed'"
        (click)="isShowPassword = !isShowPassword"
        pnFormFieldSuffix
      ></pn-icon>
      <pn-input-text
        formControlName="password"
        [inputType]="isShowPassword ? 'text' : 'password'"
        (keyup.enter)="submitFormLogin()"
      ></pn-input-text>
      <pn-error *ngIf="form.controls.password.getError('required')"></pn-error>
    </pn-form-field>
  }

  <div class="sign-in-bottom">
    <pn-checkbox formControlName="remember_me">Запомнить меня</pn-checkbox>
    <a routerLink="/forgot-password">Забыли пароль?</a>
  </div>

  <pn-button
    theme="primary"
    type="submit"
    [disabled]="form.invalid"
    [width]="'100%'"
    (buttonClick)="
      authType === authTypeEnum.Phone ? submitFormPhone() : submitFormLogin()
    "
    [loading]="loading"
  >
    @if (authType === authTypeEnum.Phone) {
      Далее
    } @else {
      Войти в аккаунт
    }
  </pn-button>
</pn-auth-body>
<pn-auth-bottom>
  У вас нет аккаунта? <br />
  <a routerLink="/sign-up">Зарегистрируйтесь прямо сейчас</a>
</pn-auth-bottom>
