import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthHeaderComponent } from '@app/auth/components/auth-header/auth-header.component';

@Component({
  selector: 'pn-auth-shell',
  standalone: true,
  imports: [RouterOutlet, AuthHeaderComponent],
  templateUrl: './auth-shell.component.html',
  styleUrl: './auth-shell.component.scss',
})
export class AuthShellComponent {}
