<button
  class="sidebar-profile"
  [class.collapsed]="isSidebarCollapsed"
  #triggerButton
  (click)="toggleMenu()"
>
  <div class="sidebar-profile__avatar">{{ getIconName() }}</div>
  <div class="sidebar-profile__name">{{ getName() }}</div>
  <div class="sidebar-profile__icon">
    <pn-icon icon="angle-bottom"></pn-icon>
  </div>
</button>
