<pn-button
  *ngIf="toasts.length > 1"
  theme="primary"
  modifier="link"
  (buttonClick)="removeAll()"
  >Удалить все</pn-button
>

@for (toast of toasts; track toast) {
  <pn-toasts-card
    [theme]="toast.theme"
    (remove)="onRemove(toast.id)"
    [@toastAnimation]
    >{{ toast.message }}</pn-toasts-card
  >
}
