import { Component, OnDestroy } from '@angular/core';
import { SidebarService } from '@core/components/sidebar/services/sidebar.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'pn-sidebar-burger',
  standalone: true,
  imports: [],
  templateUrl: './sidebar-burger.component.html',
  styleUrl: './sidebar-burger.component.scss',
})
export class SidebarBurgerComponent implements OnDestroy {
  isActive: boolean = false;
  destroy$ = new Subject<void>();

  constructor(private sidebarService: SidebarService) {
    this.sidebarService.isBurgerActive$.subscribe((active) => {
      this.isActive = active;
    });
  }

  toggleBurger() {
    this.sidebarService.toggleBurger();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
