<div class="text">
  <ng-content></ng-content>
</div>
<pn-button
  theme="none"
  icon="remove"
  iconWidth="18"
  iconHeight="18"
  iconTheme="white"
  class="remove"
  (buttonClick)="onRemove()"
></pn-button>
