import { HttpInterceptorFn } from '@angular/common/http';

export const jsonInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.headers.has('x-skip-json')) {
    const clone = req.clone({
      headers: req.headers.delete('x-skip-json'),
    });
    return next(clone);
  }

  const isFormData = req.body instanceof FormData;
  if (!isFormData) {
    let headers = req.headers;
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    const clone = req.clone({
      headers,
    });
    return next(clone);
  }
  return next(req);
};
