<svg
  width="140"
  height="36"
  viewBox="0 0 140 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class="logo"
>
  <g class="logo__icon">
    <path
      d="M36.857 36c9.904 0 17.933-8.059 17.933-18S46.76 0 36.857 0c-9.905 0-17.934 8.059-17.934 18s8.03 18 17.934 18z"
      fill="#339967"
    />
    <path
      d="m36.747 27.11-7.757-4.528V11.153l7.757-7.123h.825l7.096 7.068v11.098l-7.041 4.914h-.88z"
      fill="#F4FFE3"
    />
    <path d="M36.637 4.03v12.037L30.31 9.938l6.326-5.908z" fill="#86BF6E" />
    <path
      d="m36.637 22.803-7.647-4.748v-6.902l.935-.883 6.712 6.57v5.963z"
      fill="#89BD2D"
    />
    <path
      d="M28.99 22.583v-3.865l7.647 4.693v3.7l-7.647-4.528zM37.682 20.595l6.986-4.251v5.852l-6.986 4.915v-6.516z"
      fill="#338054"
    />
    <path
      d="M37.682 4.03v15.902l6.986-4.196v-4.638L37.682 4.03z"
      fill="#89BD2D"
    />
    <path d="M37.682 4.03h-1.045V31.97h1.045V4.03z" fill="#EFFCDB" />
  </g>
  <g class="logo__text">
    <path
      d="M0 9.718h13.807V27.11h-3.52V12.644H3.52v14.522H0V9.718zM60.62 9.718h12.158V27.11h-3.52V12.644H64.03v7.454c0 2.154-.055 3.644-.22 4.473-.165.828-.495 1.49-1.045 1.987-.55.497-1.43.718-2.585.718-.385 0-1.155-.055-2.256-.11V24.46h.77c.716 0 1.21-.11 1.486-.276.275-.22.385-.663.385-1.436V9.717h.055zM76.409 9.718h3.3v11.595L86.75 9.718h3.466V27.11h-3.3V15.736L79.873 27.11h-3.52V9.718h.055zM93.957 9.718h11.772v2.926h-8.252v14.522h-3.52V9.718zM106.499 18.497c0-1.767.275-3.258.77-4.473.385-.883.935-1.656 1.595-2.374a6.133 6.133 0 0 1 2.256-1.546c1.1-.442 2.31-.663 3.741-.663 2.53 0 4.62.773 6.161 2.375 1.54 1.6 2.31 3.81 2.31 6.625 0 2.816-.77 5.025-2.31 6.626-1.541 1.601-3.576 2.374-6.106 2.374-2.586 0-4.621-.773-6.162-2.374-1.485-1.601-2.255-3.754-2.255-6.57zm3.631-.11c0 1.987.44 3.478 1.375 4.472.88.993 2.035 1.546 3.466 1.546 1.43 0 2.53-.497 3.465-1.491.88-.994 1.32-2.54 1.32-4.528 0-1.988-.44-3.478-1.32-4.472-.88-.994-2.035-1.491-3.465-1.491-1.431 0-2.586.497-3.466 1.49-.935.995-1.375 2.485-1.375 4.473zM126.083 27.11V9.718h3.52v6.846h6.876V9.718H140V27.11h-3.521v-7.62h-6.876v7.62h-3.52z"
      fill="#818181"
    />
  </g>
</svg>
