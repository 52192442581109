/* src/app/_core/components/sidebar/components/sidebar-navigation/sidebar-navigation.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  margin-top: 60px;
  transition: margin 0.3s ease;
  margin-bottom: 45px;
  display: block;
}
.navigation-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
/*# sourceMappingURL=sidebar-navigation.component.css.map */
