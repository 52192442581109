import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  private _screenWidth: number = 0;
  private _screenWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(
    window.innerWidth
  );
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.checkScreenWidth();
    this.renderer.listen('window', 'resize', () => this.checkScreenWidth());
  }

  private checkScreenWidth() {
    this._screenWidth = window.innerWidth;
    this._screenWidth$.next(this._screenWidth);
  }

  get screenWidth(): number {
    return this._screenWidth;
  }

  get screenWidth$(): Observable<number> {
    return this._screenWidth$.asObservable();
  }
}
