import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private _isCollapsed = new BehaviorSubject<boolean>(false);
  private _isBurgerActive = new BehaviorSubject<boolean>(false);

  get isCollapsed() {
    return this._isCollapsed.value;
  }

  get isCollapsed$() {
    return this._isCollapsed.asObservable();
  }

  toggleSidebar() {
    this._isCollapsed.next(!this._isCollapsed.value);
  }

  get isBurgerActive() {
    return this._isBurgerActive.value;
  }

  get isBurgerActive$() {
    return this._isBurgerActive.asObservable();
  }

  toggleBurger() {
    this._isBurgerActive.next(!this._isBurgerActive.value);
  }
}
