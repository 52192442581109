import { Component, HostBinding, input, output } from '@angular/core';
import { ToastTheme } from '@shared/components/base/toasts/models/toast.model';

@Component({
  selector: 'pn-toasts-card',
  templateUrl: './toasts-card.component.html',
  styleUrl: './toasts-card.component.scss',
})
export class ToastsCardComponent {
  theme = input<ToastTheme>('success');
  remove = output<void>();

  @HostBinding('class') get hostClass() {
    return this.theme();
  }

  onRemove() {
    this.remove.emit();
  }
}
