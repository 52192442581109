import { User, UserTypeEnum } from '@shared/models/user';

export function getName(user: User | undefined): string {
  if (!user) return '';

  const { user_type } = user;
  switch (user_type) {
    case UserTypeEnum.Administrator:
      return 'Администратор';
    case UserTypeEnum.Person:
      return `${user.middle_name} ${user.first_name} ${user.last_name}`;
    case UserTypeEnum.BusinessOwner:
      return `${user.individual_name}`;
    case UserTypeEnum.Company:
      return `${user.organization_name}`;
    default:
      return 'Пользователь';
  }
}
