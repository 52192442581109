/* src/app/_shared/components/base/toasts/components/toasts/toasts.component.scss */
:host {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 100vh;
  overflow: auto;
  width: 340px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
  padding: 20px 20px 20px 0;
  align-items: center;
}
:host.hide {
  display: none;
}
/*# sourceMappingURL=toasts.component.css.map */
