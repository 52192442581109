import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastsService } from '@shared/components/base/toasts/services/toasts.service';
import { Toast } from '@shared/components/base/toasts/models/toast.model';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'pn-toasts',
  templateUrl: './toasts.component.html',
  styleUrl: './toasts.component.scss',
  animations: [
    trigger('toastAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('200ms ease', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ToastsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  toasts: Toast[] = [];

  @HostBinding('class.hide') get hide() {
    return !this.toasts.length;
  }

  constructor(private toastService: ToastsService) {}

  ngOnInit() {
    this.toastService.toasts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((toasts) => {
        this.toasts = toasts;
      });
  }

  onRemove(id: number) {
    this.toastService.removeToast(id);
  }

  removeAll() {
    this.toastService.removeAll();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
