import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastsComponent } from '@shared/components/base/toasts/components/toasts/toasts.component';
import { ToastsCardComponent } from '@shared/components/base/toasts/components/toast-card/toasts-card.component';
import { ButtonComponent } from '@shared/components/base/button/button.component';

@NgModule({
  declarations: [ToastsComponent, ToastsCardComponent],
  imports: [CommonModule, ButtonComponent],
  exports: [ToastsComponent, ToastsCardComponent],
})
export class ToastsModule {}
