import {
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SidebarBaseComponent } from './components/sidebar-base.component';
import { ScreenService } from '@shared/services/screen.service';
import { takeUntil } from 'rxjs';
import { SidebarNavigationComponent } from '@core/components/sidebar/components/sidebar-navigation/sidebar-navigation.component';
import { SidebarProfileComponent } from '@core/components/sidebar/components/sidebar-profile/sidebar-profile.component';
import { SidebarBurgerComponent } from '@core/components/sidebar/components/sidebar-burger/sidebar-burger.component';
import { SidebarToggleComponent } from '@core/components/sidebar/components/sidebar-toggle/sidebar-toggle.component';
import { SidebarLogoComponent } from '@core/components/sidebar/components/sidebar-logo/sidebar-logo.component';

@Component({
  selector: 'pn-sidebar',
  standalone: true,
  imports: [
    SidebarNavigationComponent,
    SidebarProfileComponent,
    SidebarBurgerComponent,
    SidebarToggleComponent,
    SidebarLogoComponent,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent
  extends SidebarBaseComponent
  implements OnInit, OnDestroy
{
  isMobile: boolean = false;

  @HostBinding('class.collapsed') get isCollapsed() {
    return this.isSidebarCollapsed;
  }
  @HostBinding('class.shrink') isShrink = false;
  @HostBinding('class.burger-active') isBurgerActive = false;

  @HostListener('window:scroll', ['$event']) handleScroll() {
    const offset = window.scrollY;
    this.isShrink = offset > 20;
  }

  constructor(private screenService: ScreenService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.screenService.screenWidth$
      .pipe(takeUntil(this.destroy$))
      .subscribe((width) => {
        this.isMobile = width < 1199;
      });

    this.sidebarService.isBurgerActive$
      .pipe(takeUntil(this.destroy$))
      .subscribe((active) => {
        this.isBurgerActive = active;
      });
  }
}
