import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  isHandset: boolean = false;
  isTablet: boolean = false;
  isWeb: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isHandset = result.matches;
      });

    this.breakpointObserver
      .observe([Breakpoints.Tablet])
      .subscribe((result) => {
        this.isTablet = result.matches;
      });

    this.breakpointObserver.observe([Breakpoints.Web]).subscribe((result) => {
      this.isWeb = result.matches;
    });
  }
}
