import { Route, Routes } from '@angular/router';
import { ShellComponent } from '@core/components/shell/shell.component';
import { AuthShellComponent } from '@app/auth/components/auth-shell/auth-shell.component';
import { SignInComponent } from '@app/auth/sign-in/pages/sign-in/sign-in.component';
import { PageNotFountComponent } from '@core/components/page-not-fount/page-not-fount.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { KitComponent } from '@app/kit/kit.component';
import { ConfirmEmailComponent } from '@app/auth/confirm-email/pages/confirm-email/confirm-email.component';

function shellChildRoutes(routes: Routes): Route {
  return {
    path: '',
    component: ShellComponent,
    children: routes,
    canActivate: [AuthGuard],
    data: { reuse: true },
  };
}

function authShellChildRoutes(routes: Routes): Route {
  return {
    path: '',
    component: AuthShellComponent,
    children: routes,
    data: { reuse: true },
  };
}

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full',
  },
  shellChildRoutes([
    {
      path: 'profile',
      loadChildren: () =>
        import('./profile/profile.module').then((m) => m.ProfileModule),
    },
    {
      path: 'users',
      loadChildren: () =>
        import('./users/users.module').then((m) => m.UsersModule),
    },
    {
      path: 'integrations',
      loadChildren: () =>
        import('./integrations/integrations.module').then(
          (m) => m.IntegrationsModule
        ),
    },
    {
      path: 'dictionary',
      loadChildren: () =>
        import('./dictionary/dictionary.module').then(
          (m) => m.DictionaryModule
        ),
    },
    {
      path: 'wastes-sources',
      loadChildren: () =>
        import('./wastes-sources/wastes-sources.module').then(
          (m) => m.WastesSourcesModule
        ),
    },
    {
      path: 'transports',
      loadChildren: () =>
        import('./transports/transports.module').then(
          (m) => m.TransportsModule
        ),
    },
    {
      path: 'notifications-settings',
      loadChildren: () =>
        import('./notifications-settings/notifications-settings.module').then(
          (m) => m.NotificationsSettingsModule
        ),
    },
    {
      path: 'requests',
      loadChildren: () =>
        import('./requests/requests.module').then((m) => m.RequestsModule),
    },
    {
      path: 'contracts',
      loadChildren: () =>
        import('./contracts/contracts.module').then((m) => m.ContractsModule),
    },
    {
      path: 'reports',
      loadChildren: () =>
        import('./reports/reports.module').then((m) => m.ReportsModule),
    },
    {
      path: 'notifications',
      loadChildren: () =>
        import('./notifications/notifications.module').then(
          (m) => m.NotificationsModule
        ),
    },
  ]),
  authShellChildRoutes([
    {
      path: 'sign-in',
      component: SignInComponent,
    },
    {
      path: 'sign-up',
      loadChildren: () =>
        import('./auth/sign-up/sign-up.module').then((m) => m.SignUpModule),
    },
    {
      path: 'confirm-email',
      component: ConfirmEmailComponent,
    },
  ]),
  { path: 'kit', component: KitComponent },
  { path: '**', component: PageNotFountComponent },
];
