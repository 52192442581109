import { Component, output } from '@angular/core';
import { IconComponent } from '@shared/components/base/icon/icon.component';
import { ButtonComponent } from '@shared/components/base/button/button.component';
import { SidebarBaseComponent } from '@core/components/sidebar/components/sidebar-base.component';
import { getName } from '@core/components/sidebar/utils/get-name.utils';

@Component({
  selector: 'pn-sidebar-profile-menu',
  standalone: true,
  imports: [IconComponent, ButtonComponent],
  templateUrl: './sidebar-profile-menu.component.html',
  styleUrl: './sidebar-profile-menu.component.scss',
  host: {
    class: 'sidebar-profile-menu',
  },
})
export class SidebarProfileMenuComponent extends SidebarBaseComponent {
  menuSelect = output<string>();
  menuClose = output<void>();
  logout = output<void>();

  getName(): string {
    return getName(this.user);
  }

  onMenuSelect(id: string) {
    this.menuSelect.emit(id);
  }

  onMenuClose() {
    this.menuClose.emit();
  }

  onLogout() {
    this.logout.emit();
  }
}
