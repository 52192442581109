/* src/app/_core/components/sidebar/sidebar.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  position: fixed;
  left: 0;
  top: 0;
  width: 311px;
  height: 100%;
  border-right: 1px solid #ced2da;
  z-index: 100;
  padding: 45px 0;
  overflow: auto;
  transition:
    width 0.3s ease,
    padding 0.3s ease,
    height 0.3s ease,
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
}
@media (width <= 1199px) {
  :host {
    width: 100%;
    height: auto;
    padding: 40px 0;
    border-right: 0;
  }
  :host.shrink {
    padding: 15px 0;
    background-color: #fff;
    box-shadow: 0 25px 40px -10px rgba(28, 39, 49, 0.08);
  }
  :host.burger-active {
    height: 100%;
  }
}
@media (width <= 767px) {
  :host {
    padding: 20px 0;
  }
  :host.shrink {
    padding: 10px 0;
  }
}
@media (min-width: 1200px) {
  :host.collapsed {
    width: 70px;
  }
  :host.collapsed .sidebar-head {
    padding: 0 15px;
  }
}
.sidebar-head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 35px;
  transition: padding 0.3s ease;
}
@media (width <= 1199px) {
  .sidebar-head {
    justify-content: space-between;
    padding: 0 40px;
  }
}
@media (width <= 767px) {
  .sidebar-head {
    justify-content: space-between;
    padding: 0 20px;
  }
}
.sidebar-container {
  margin-top: 40px;
}
@media (width <= 1199px) {
  .sidebar-container {
    display: none;
    background-color: #f8f8f8;
    animation: fade-in 0.3s ease 1 forwards;
  }
  :host.shrink .sidebar-container {
    background-color: #fff;
  }
  :host.burger-active .sidebar-container {
    display: block;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*# sourceMappingURL=sidebar.component.css.map */
