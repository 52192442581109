import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SidebarService } from '../services/sidebar.service';
import { User } from '@shared/models/user';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'pn-sidebar-base',
  standalone: true,
  imports: [],
  template: '',
})
export class SidebarBaseComponent implements OnInit, OnDestroy {
  isSidebarCollapsed: boolean = false;
  protected destroy$ = new Subject<void>();
  protected sidebarService = inject(SidebarService);
  protected userService = inject(UserService);
  user!: User;

  ngOnInit() {
    this.sidebarService.isCollapsed$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.isSidebarCollapsed = state;
      });

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
