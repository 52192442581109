import { Component } from '@angular/core';
import { SidebarBaseComponent } from '@core/components/sidebar/components/sidebar-base.component';

@Component({
  selector: 'pn-sidebar-toggle',
  standalone: true,
  imports: [],
  templateUrl: './sidebar-toggle.component.html',
  styleUrl: './sidebar-toggle.component.scss',
})
export class SidebarToggleComponent extends SidebarBaseComponent {
  constructor() {
    super();
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }
}
