<button
  class="sidebar-profile-menu__button sidebar-profile-menu__button--first"
  (click)="onMenuClose()"
>
  <span class="sidebar-profile-menu__button-text">{{ getName() }}</span>
  <span class="sidebar-profile-menu__button-icon">
    <pn-icon icon="angle-bottom"></pn-icon>
  </span>
</button>
<!--<button class="sidebar-profile-menu__button">
  <span class="sidebar-profile-menu__button-text">ООО “Стройматериалы”</span>
</button>
<button class="sidebar-profile-menu__button">
  <span class="sidebar-profile-menu__button-text">ООО “Организация”</span>
</button>-->
<button class="sidebar-profile-menu__button" (click)="onLogout()">
  Выйти из аккаунта
</button>
<div class="sidebar-profile-menu__bottom">
  <button class="sidebar-profile-menu__add">
    <span class="sidebar-profile-menu__add-icon">
      <pn-icon icon="plus"></pn-icon>
    </span>
    <span>Добавить пользователя</span>
  </button>
</div>
