import { Component, OnInit } from '@angular/core';
import { LoadingComponent } from '@shared/components/base/loading/loading.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { ToastsService } from '@shared/components/base/toasts/services/toasts.service';

@Component({
  selector: 'pn-confirm-email',
  standalone: true,
  imports: [LoadingComponent],
  templateUrl: './confirm-email.component.html',
  styleUrl: './confirm-email.component.scss',
})
export class ConfirmEmailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastsService: ToastsService
  ) {}

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      this.authService.confirmEmail(token).subscribe({
        next: () => {
          this.toastsService.addToast('Email успешно подтвержден', 'success');
          this.router.navigate(['/']);
        },
        error: () => {
          this.router.navigate(['/']);
        },
      });
    } else {
      this.toastsService.addToast('Не найден код подтверждения', 'error');
      this.router.navigate(['/']);
    }
  }
}
