/* src/app/auth/components/auth-header/auth-header.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
@media (width <= 575px) {
  :host {
    padding: 0 20px;
    top: 34px;
  }
}
.logo {
  flex: 0 0 140px;
  width: 140px;
}
@media (width <= 575px) {
  .logo {
    flex: 0 0 120px;
    width: 120px;
  }
}
/*# sourceMappingURL=auth-header.component.css.map */
