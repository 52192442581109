<button
  class="button button-burger"
  type="button"
  [class.active]="isActive"
  (click)="toggleBurger()"
>
  <span class="button-burger__icon"
    ><span class="button-burger__line"></span
    ><span class="button-burger__line"></span
    ><span class="button-burger__line"></span
  ></span>
</button>
