import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionService } from '@core/services/permission.service';

@Directive({
  selector: '[pnHasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnDestroy {
  private subscription!: Subscription;

  @Input() set pnHasPermission(scope: string | string[]) {
    this.updateView(scope);
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  private updateView(scope: string | string[]) {
    this.subscription?.unsubscribe();
    this.subscription = this.permissionService
      .hasPermission$(scope)
      .subscribe((hasPermission) => {
        this.viewContainer.clear();
        if (hasPermission) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
