import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { SidebarBaseComponent } from '@core/components/sidebar/components/sidebar-base.component';

@Component({
  selector: 'pn-sidebar-logo',
  standalone: true,
  imports: [],
  templateUrl: './sidebar-logo.component.html',
  styleUrl: './sidebar-logo.component.scss',
})
export class SidebarLogoComponent
  extends SidebarBaseComponent
  implements OnInit, OnDestroy
{
  @HostBinding('class.collapsed')
  get collapsed() {
    return this.isSidebarCollapsed;
  }

  constructor() {
    super();
  }
}
