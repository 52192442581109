import {
  Component,
  HostBinding,
  input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IconComponent } from '@shared/components/base/icon/icon.component';
import { NavigationItemModel } from './models/sidebar-navigation-item.model';
import { SidebarBaseComponent } from '@core/components/sidebar/components/sidebar-base.component';
import { DeviceService } from '@shared/services/device.service';

@Component({
  selector: 'pn-navigation-item',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, RouterLink, IconComponent],
  templateUrl: './navigation-item.component.html',
  styleUrl: './navigation-item.component.scss',
  providers: [RouterLinkActive],
})
export class NavigationItemComponent
  extends SidebarBaseComponent
  implements OnInit, OnDestroy
{
  navigationItem = input.required<NavigationItemModel>();

  @HostBinding('class.active') isActive = false;
  @HostBinding('class.collapsed') get getCollapsed(): boolean {
    return this.isSidebarCollapsed;
  }
  constructor(private deviceService: DeviceService) {
    super();
  }

  onRouterLinkActive(active: boolean) {
    this.isActive = active;
  }

  onClick() {
    if (this.deviceService.isHandset || this.deviceService.isTablet) {
      this.sidebarService.toggleBurger();
    }
  }
}
