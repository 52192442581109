/* src/app/auth/sign-in/pages/sign-in/sign-in.component.scss */
:host {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
pn-form-field,
pn-checkbox {
  margin-top: 0;
}
.sign-in-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sign-in-bottom a {
  font-size: 12px;
}
/*# sourceMappingURL=sign-in.component.css.map */
