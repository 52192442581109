<button
  class="sidebar-toggle"
  [class.collapsed]="isSidebarCollapsed"
  (click)="toggleSidebar()"
>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="#637083"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.2,12.7c-0.1,0-0.3-0.1-0.4-0.2L5.1,8.8C4.9,8.6,4.7,8.3,4.7,8c0-0.3,0.1-0.7,0.4-0.9l3.7-3.7
	c0.2-0.2,0.6-0.2,0.8,0c0.2,0.2,0.2,0.6,0,0.8L5.9,7.8c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0.1,0.1l3.7,3.7c0.2,0.2,0.2,0.6,0,0.8
	C9.5,12.7,9.3,12.7,9.2,12.7z"
    />
  </svg>
</button>
