/* src/app/_shared/components/base/toasts/components/toast-card/toasts-card.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
:host {
  display: flex;
  padding: 20px;
  border-radius: 8px;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}
:host.success {
  background-image:
    linear-gradient(
      111deg,
      #89bd2d 0%,
      #338054 100%);
}
:host.error {
  background-image:
    linear-gradient(
      111deg,
      #bd2d2d 0%,
      #a42727 100%);
}
.text {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.64px;
  color: #fff;
  flex-shrink: 1;
  min-width: 0;
  padding-top: 3px;
}
.remove {
  flex: 0 0 30px;
}
/*# sourceMappingURL=toasts-card.component.css.map */
