/* src/app/auth/components/auth-shell/auth-shell.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  padding: 150px 20px 40px;
  display: block;
}
@media (width <= 575px) {
  :host {
    padding: 120px 20px 40px;
  }
}
.container {
  margin: 0 auto;
  max-width: 480px;
  width: 100%;
  padding: 40px 32px;
  border-radius: 8px;
  box-shadow: 0 25px 40px -10px rgba(28, 39, 49, 0.08);
  background-color: #fff;
}
@media (width <= 575px) {
  .container {
    padding: 40px 20px;
  }
}
/*# sourceMappingURL=auth-shell.component.css.map */
