<pn-file-upload
  uploadUrl="http://localhost:8008/api/upload"
  [multiple]="false"
></pn-file-upload>

<pn-form-field>
  <pn-label pnLabelFloat>test</pn-label>
  <pn-form-field pnLabelFloat>
    <pn-label>Test</pn-label>
    <pn-datepicker
      [formControl]="datepicker"
      (change)="changeDatepicker($event)"
    ></pn-datepicker>
  </pn-form-field>
  <pn-button theme="primary" (buttonClick)="changeDatepicker('')"
    >test</pn-button
  >

  <pn-divider></pn-divider>

  <pn-form-field pnLabelFloat>
    <pn-label>toast message</pn-label>
    <pn-input-text
      [formControl]="formControl"
      placeholder="test"
    ></pn-input-text>
    <pn-error *ngIf="formControl.getError('required')">error</pn-error>
  </pn-form-field>

  <pn-divider></pn-divider>

  <pn-button theme="primary" (buttonClick)="testToast('success')"
    >test toast success</pn-button
  >
  <pn-button theme="error" (buttonClick)="testToast('error')"
    >test toast error</pn-button
  >

  <pn-form-field>
    <pn-label>test</pn-label>
    <pn-icon icon="search" pnFormFieldPrefix></pn-icon>
    <pn-icon icon="search" pnFormFieldSuffix></pn-icon>
    <pn-textarea
      [formControl]="formControl2"
      placeholder="test"
      [autosize]="true"
    ></pn-textarea>
    <pn-error *ngIf="formControl.getError('required')">error</pn-error>
  </pn-form-field>

  <pn-checkbox-list>
    <pn-checkbox>test</pn-checkbox>
    <pn-checkbox>test</pn-checkbox>
  </pn-checkbox-list>

  <pn-form-field>
    <pn-label>test</pn-label>
    <ng-select [(ngModel)]="selectedCar">
      @for (car of cars; track car.id) {
        <ng-option [value]="car.id">{{ car.name }}</ng-option>
      }
    </ng-select>
  </pn-form-field>

  <pn-form-field>
    <pn-label>test 2</pn-label>
    <ng-select [formControl]="formControl3" [addTag]="true">
      @for (car of cars; track car.id) {
        <ng-option [value]="car.id">{{ car.name }}</ng-option>
      }
    </ng-select>
  </pn-form-field>

  <pn-mat-table-responsive>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          <pn-table-sorting-button
            [active]="activeSorting === 'id'"
            (buttonClick)="sorting('id', $event.sortAscending)"
            >ID</pn-table-sorting-button
          >
        </th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          <pn-table-sorting-button
            [active]="activeSorting === 'name'"
            (buttonClick)="sorting('name', $event.sortAscending)"
            >Наименование отходов</pn-table-sorting-button
          >
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>
          <pn-table-sorting-button
            [active]="activeSorting === 'code'"
            (buttonClick)="sorting('code', $event.sortAscending)"
            >Код ФККО</pn-table-sorting-button
          >
        </th>
        <td mat-cell *matCellDef="let element">{{ element.code }}</td>
      </ng-container>

      <ng-container matColumnDef="hazardClass">
        <th mat-header-cell *matHeaderCellDef>
          <pn-table-sorting-button
            [active]="activeSorting === 'hazardClass'"
            (buttonClick)="sorting('hazardClass', $event.sortAscending)"
            >Класс опасности</pn-table-sorting-button
          >
        </th>
        <td mat-cell *matCellDef="let element">{{ element.hazardClass }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef>
          <pn-table-controls>
            <pn-button
              theme="none"
              icon="edit"
              iconWidth="20"
              iconHeight="20"
              iconTheme="primary"
              (buttonClick)="test1()"
            ></pn-button>
            <pn-button
              theme="none"
              icon="remove"
              iconWidth="20"
              iconHeight="20"
              iconTheme="error"
              (buttonClick)="test2()"
            ></pn-button>
          </pn-table-controls>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </pn-mat-table-responsive>
</pn-form-field>
