/* src/app/_core/components/sidebar/components/sidebar-burger/sidebar-burger.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  display: inline-block;
}
.button-burger {
  display: none;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 30;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}
@media (width <= 1199px) {
  .button-burger {
    display: block;
  }
}
.button-burger__icon {
  position: absolute;
  width: 20px;
  height: 16px;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button-burger__line {
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  background-color: #000;
  transition: all 0.6s ease;
  backface-visibility: hidden;
  transform-origin: 0 0;
  opacity: 1;
}
.button-burger__line:nth-child(1) {
  top: 0;
}
.button-burger__line:nth-child(2) {
  top: 50%;
}
.button-burger__line:nth-child(3) {
  bottom: 0;
  transform-origin: 0 100%;
}
.active .button-burger__line {
  width: 22px;
}
.active .button-burger__line:nth-child(1) {
  transform: rotateZ(45deg);
  left: 3px;
}
.active .button-burger__line:nth-child(2) {
  transform: translateX(-10px);
  opacity: 0;
}
.active .button-burger__line:nth-child(3) {
  transform: rotateZ(-45deg);
  left: 3px;
}
/*# sourceMappingURL=sidebar-burger.component.css.map */
