import { Injectable } from '@angular/core';
import { ToastsService } from '@shared/components/base/toasts/services/toasts.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  constructor(private toastsService: ToastsService) {}

  addError(message: string): void {
    this.toastsService.addToast(message, 'error');
  }
}
