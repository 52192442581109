import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  apiInterceptor,
  errorInterceptor,
  jsonInterceptor,
  tokenInterceptor,
} from '@core/interceptors';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';
import {
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
} from '@danielmoncada/angular-datetime-picker';

const YANDEX_CONFIG: YaConfig = {
  apikey: '5eb887bc-7b42-433d-a113-c16a07e96352',
};

const OWL_CONFIG = {
  fullPickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideEnvironmentNgxMask(),
    provideHttpClient(
      withInterceptors([
        apiInterceptor,
        jsonInterceptor,
        tokenInterceptor,
        errorInterceptor,
      ])
    ),
    importProvidersFrom(AngularYandexMapsModule.forRoot(YANDEX_CONFIG)),
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { width: '100%', maxWidth: '480px' },
    },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'ru-RU' },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: OWL_CONFIG,
    },
  ],
};
