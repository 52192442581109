<div class="auth-tabs">
  <button
    type="button"
    class="auth-tab"
    #authTabPhone
    (click)="changeAuthType(authTypeEnum.Phone, authTabActive, authTabPhone)"
  >
    Телефон
  </button>
  <button
    type="button"
    class="auth-tab"
    #authTabEmail
    (click)="changeAuthType(authTypeEnum.Login, authTabActive, authTabEmail)"
  >
    Логин
  </button>
  <div
    class="auth-tab__active"
    #authTabActive
    [class.auth-tab__active--right]="authType === authTypeEnum.Login"
  ></div>
</div>
