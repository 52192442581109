import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.headers.has('x-skip-token')) {
    const clone = req.clone({
      headers: req.headers.delete('x-skip-token'),
    });
    return next(clone);
  }

  const token = inject(AuthService).getToken();
  if (token) {
    const clone = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
    return next(clone);
  }

  return next(req);
};
