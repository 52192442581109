import { Component } from '@angular/core';

@Component({
  selector: 'pn-page-not-fount',
  standalone: true,
  imports: [],
  templateUrl: './page-not-fount.component.html',
  styleUrl: './page-not-fount.component.scss',
})
export class PageNotFountComponent {}
