import { Component, OnDestroy } from '@angular/core';
import { SIDEBAR_NAVIGATION_ITEMS } from './constants/sidebar-navigation-items.constant';
import { NavigationItemModel } from '@core/components/sidebar/components/sidebar-navigation/components/navigation-item/models/sidebar-navigation-item.model';
import { NavigationItemComponent } from '@core/components/sidebar/components/sidebar-navigation/components/navigation-item/navigation-item.component';
import { NgForOf } from '@angular/common';
import { SidebarBaseComponent } from '@core/components/sidebar/components/sidebar-base.component';
import { HasPermissionDirective } from '@core/directives/has-permission.directive';

@Component({
  selector: 'pn-sidebar-navigation',
  standalone: true,
  imports: [NavigationItemComponent, NgForOf, HasPermissionDirective],
  templateUrl: './sidebar-navigation.component.html',
  styleUrl: './sidebar-navigation.component.scss',
})
export class SidebarNavigationComponent
  extends SidebarBaseComponent
  implements OnDestroy
{
  navigationItems: NavigationItemModel[] = SIDEBAR_NAVIGATION_ITEMS;
  constructor() {
    super();
  }
}
