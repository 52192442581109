import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { HeadTitleService } from '@core/services/head-title.service';
import { ToastsModule } from '@shared/components/base/toasts/toasts.module';

@Component({
  selector: 'pn-root',
  standalone: true,
  imports: [RouterOutlet, ToastsModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(
    private ngSelectConfig: NgSelectConfig,
    private headTitleService: HeadTitleService
  ) {
    this.ngSelectConfig.appendTo = 'body';
    this.ngSelectConfig.notFoundText = 'Ничего не найдено';
    this.ngSelectConfig.loadingText = 'Загрузка...';
    this.ngSelectConfig.typeToSearchText = 'Поиск';
    this.ngSelectConfig.clearAllText = 'Очистить все';
    this.ngSelectConfig.addTagText = 'Добавить опцию';
  }

  ngOnInit() {
    this.headTitleService.init();
  }
}
