import {
  Component,
  ElementRef,
  OnInit,
  output,
  Renderer2,
  viewChild,
} from '@angular/core';

export enum AuthTypeEnum {
  Phone,
  Login,
}

@Component({
  selector: 'pn-sign-in-tabs',
  standalone: true,
  imports: [],
  templateUrl: './sign-in-tabs.component.html',
  styleUrl: './sign-in-tabs.component.scss',
})
export class SignInTabsComponent implements OnInit {
  authTypeEnum = AuthTypeEnum;
  authType: AuthTypeEnum = AuthTypeEnum.Phone;
  authTabPhone = viewChild<ElementRef>('authTabPhone');
  authTabActive = viewChild<ElementRef>('authTabActive');

  changeTab = output<AuthTypeEnum>();

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    const buttonWidth = this.authTabPhone()?.nativeElement.offsetWidth + 'px';
    this.renderer.setStyle(
      this.authTabActive()?.nativeElement,
      'width',
      buttonWidth
    );
  }

  changeAuthType(
    authType: AuthTypeEnum,
    authTabActive: HTMLElement,
    authTabButton: HTMLElement
  ) {
    this.authType = authType;
    const buttonWidth = authTabButton.offsetWidth + 'px';
    this.renderer.setStyle(authTabActive, 'width', buttonWidth);
    this.changeTab.emit(authType);
  }
}
