import { Component, ElementRef, OnDestroy, viewChild } from '@angular/core';
import { SidebarBaseComponent } from '@core/components/sidebar/components/sidebar-base.component';
import { IconComponent } from '@shared/components/base/icon/icon.component';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SidebarProfileMenuComponent } from '@core/components/sidebar/components/sidebar-profile/sidebar-profile-menu/sidebar-profile-menu.component';
import { getName } from '@core/components/sidebar/utils/get-name.utils';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/auth/services/auth.service';

@Component({
  selector: 'pn-sidebar-profile',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './sidebar-profile.component.html',
  styleUrl: './sidebar-profile.component.scss',
})
export class SidebarProfileComponent
  extends SidebarBaseComponent
  implements OnDestroy
{
  private triggerButton =
    viewChild<ElementRef<HTMLButtonElement>>('triggerButton');
  private overlayRef!: OverlayRef;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private overlay: Overlay,
    private authService: AuthService
  ) {
    super();
  }

  getName(): string {
    return getName(this.user);
  }

  getIconName(): string {
    const name = this.getName();
    const [first, second] = name.split(' ');

    return `${first ? first[0] : ''}${second ? second[0] : ''}`.toUpperCase();
  }

  toggleMenu() {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  openMenu() {
    const triggerButton = this.triggerButton();
    if (!triggerButton) {
      return;
    }

    const triggerRect = triggerButton.nativeElement.getBoundingClientRect();
    this.subscriptions = new Subscription();

    const position: ConnectedPosition = {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: -48,
    };

    if (this.isSidebarCollapsed) {
      position.originX = 'end';
      position.originY = 'top';
      position.overlayX = 'start';
      position.overlayY = 'top';
      position.offsetY = -19;
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(triggerButton)
      .withPositions([position])
      .withFlexibleDimensions(false)
      .withPush(false);

    this.overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef.updateSize({
      width: triggerRect.width < 300 ? 300 : triggerRect.width - 10,
    });

    const portal = new ComponentPortal(SidebarProfileMenuComponent);
    const compRef = this.overlayRef.attach(portal);

    this.subscriptions.add(
      compRef.instance.menuSelect.subscribe((selected: string) => {
        this.closeMenu();
      })
    );

    this.subscriptions.add(
      compRef.instance.menuClose.subscribe(() => {
        this.closeMenu();
      })
    );

    this.subscriptions.add(
      compRef.instance.logout.subscribe(() => {
        this.closeMenu();
        this.authService.logout();
      })
    );

    this.subscriptions.add(
      this.overlayRef.backdropClick().subscribe(() => {
        this.closeMenu();
      })
    );
  }

  closeMenu() {
    this.subscriptions.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
  }
}
