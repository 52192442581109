import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
} from '@angular/router';
import { AuthHeaderLogoComponent } from '@app/auth/components/auth-header/auth-header-logo/auth-header-logo.component';
import { ButtonComponent } from '@shared/components/base/button/button.component';
import { filter, map, mergeMap } from 'rxjs';

@Component({
  selector: 'pn-auth-header',
  standalone: true,
  imports: [RouterLink, AuthHeaderLogoComponent, ButtonComponent],
  templateUrl: './auth-header.component.html',
  styleUrl: './auth-header.component.scss',
})
export class AuthHeaderComponent implements OnInit {
  signUp: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.getDeepRoute().data.subscribe(({ signUp }) => {
      this.signUp = signUp;
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getDeepRoute()),
        mergeMap((route) => route.data)
      )
      .subscribe(({ signUp }) => {
        this.signUp = signUp;
      });
  }

  getDeepRoute(): ActivatedRoute {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }
}
