import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '@environment/environment';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  if (!req.url.startsWith('http') && !req.url.startsWith('https')) {
    if (req.url.startsWith('/auth')) {
      const clone = req.clone({
        url: `${environment.apiUrl}${req.url}`,
      });

      return next(clone);
    } else {
      const clone = req.clone({
        url: `${environment.apiUrl}/api/v1${req.url}`,
      });

      return next(clone);
    }
  }
  return next(req);
};
