/* src/app/_core/components/sidebar/components/sidebar-navigation/components/navigation-item/navigation-item.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  opacity: 0.5;
  transition: opacity 0.3s ease;
  will-change: opacity;
  position: relative;
}
:host::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 13px 0 rgba(51, 128, 84, 0.52);
  background-color: #338054;
  transition: width 0.3s ease, opacity 0.3s ease;
}
:host:hover {
  opacity: 1;
}
:host:hover::after {
  width: 3px;
  opacity: 1;
}
:host.active {
  opacity: 1;
}
:host.active::after {
  width: 3px;
  opacity: 1;
}
@media (min-width: 1200px) {
  :host.collapsed .link {
    padding: 0 15px;
    justify-content: center;
    gap: 0;
  }
  :host.collapsed .link__text {
    width: 0;
  }
}
.link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 30px;
  padding-right: 12px;
  height: 38px;
  transition: padding 0.3s ease, gap 0.3s ease;
}
.link:hover {
  text-decoration: none;
}
.link__icon {
  flex: 0 0 24px;
  --icon-stroke: transparent;
}
:host.active .link__icon {
  --icon-fill: #338054;
  --icon-stroke: #338054;
}
.link__text {
  font-size: 16px;
  line-height: 1;
  color: #000;
  overflow: hidden;
  transition: width 0.3s ease;
}
:host.active .link__text {
  color: #338054;
  font-weight: 600;
}
/*# sourceMappingURL=navigation-item.component.css.map */
