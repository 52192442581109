<a
  class="link"
  [routerLink]="navigationItem().link"
  routerLinkActive
  (isActiveChange)="this.onRouterLinkActive($event)"
  (click)="onClick()"
>
  <div class="link__icon">
    <pn-icon [icon]="navigationItem().icon" [strokeWidth]="0.5"></pn-icon>
  </div>
  <span class="link__text">{{ navigationItem().text }}</span></a
>
