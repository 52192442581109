import { Component } from '@angular/core';

@Component({
  selector: 'pn-auth-header-logo',
  standalone: true,
  imports: [],
  templateUrl: './auth-header-logo.component.html',
  styleUrl: './auth-header-logo.component.scss',
})
export class AuthHeaderLogoComponent {}
