/* src/app/_core/components/sidebar/components/sidebar-profile/sidebar-profile.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  display: flex;
  justify-content: center;
}
@media (width <= 1199px) {
  :host {
    justify-content: flex-start;
  }
}
.sidebar-profile {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 35px;
  background: none;
  border: none;
  cursor: pointer;
  transition: padding 0.3s ease;
  max-width: 100%;
  min-width: 300px;
}
@media (min-width: 1200px) {
  .sidebar-profile.collapsed {
    padding: 0 15px;
  }
  .sidebar-profile.collapsed .sidebar-profile__avatar {
    display: flex;
  }
  .sidebar-profile.collapsed .sidebar-profile__name {
    display: none;
  }
  .sidebar-profile.collapsed .sidebar-profile__icon {
    display: none;
  }
}
.sidebar-profile:active,
.sidebar-profile:focus {
  outline: none;
}
.sidebar-profile__avatar {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  color: #338054;
  text-transform: uppercase;
  border-radius: 50%;
  border: 1px solid #338054;
  display: none;
}
.sidebar-profile__name {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  color: #338054;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar-profile__icon {
  flex: 0 0 15px;
}
.sidebar-profile__icon svg {
  display: block;
}
/*# sourceMappingURL=sidebar-profile.component.css.map */
