/* src/app/_core/components/sidebar/components/sidebar-toggle/sidebar-toggle.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  margin-top: auto;
}
.sidebar-toggle {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border-radius: 50%;
  bottom: 45px;
  background-color: #fff;
  box-shadow: 0 25px 40px -10px rgba(28, 39, 49, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease, margin-left 0.3s ease;
  border: 1px solid #338054;
  margin-left: 35px;
}
@media (width <= 1199px) {
  .sidebar-toggle {
    display: none;
  }
}
.sidebar-toggle:focus,
.sidebar-toggle:hover {
  box-shadow: 0 5px 5px 0 rgba(28, 39, 49, 0.08);
}
.sidebar-toggle:focus {
  outline: none;
}
@media (min-width: 1200px) {
  .sidebar-toggle.collapsed {
    margin-left: 15px;
  }
  .sidebar-toggle.collapsed svg {
    transform: rotate(180deg);
  }
}
.sidebar-toggle svg {
  display: block;
  transition: transform 0.3s ease;
  width: 20px;
  height: 20px;
}
/*# sourceMappingURL=sidebar-toggle.component.css.map */
